<template>
    <div v-html="svgContent" v-if="svgContent" class="svg-icon" :class="customClass"></div>
</template>
  
<script>
import { ref, watch, onMounted } from "vue";

export default {
    props: {
        src: String,
        customClass: String,
    },
    setup(props) {
        const svgContent = ref(null);

        const fetchSvg = async () => {
            try {
                const response = await fetch(props.src);
                let text = await response.text();
                
                text = text.replace(/fill="[^"]*"/g, 'fill="currentColor"');
                text = text.replace(/stroke="[^"]*"/g, 'stroke="currentColor"');

                svgContent.value = text;
            } catch (error) {
                console.error("Ошибка загрузки SVG:", error);
            }
        };

        watch(() => props.src, fetchSvg);
        onMounted(fetchSvg);

        return { svgContent };
    }
};
</script>
  