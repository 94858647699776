<template>

    <div class="loading__page">

        <img src="~@/assets/images/loadpage.png" alt="">

        <div class="loading">

            <p class="title">MoneyTube</p>

            <div class="progress__bar">
                <p :style="{ color: progress >= 55 ? '#fff' : '#414141' }">
                    {{ progress }}%
                </p>
                <div class="progress" :style="{ width: progress + '%' }"></div>
            </div>

        </div>

    </div>

</template>

<script>
import '@/styles/pages/LoadingPage.scss';

import TelegramAuth from "@/plugins/TelegramAuth";

export default {
    data() {
        return {
            progress: 0,
        };
    },
    mounted() {
        TelegramAuth.login(this.updateProgress).then(() => {
            this.$router.replace("/");
        }).catch(error => {
            console.error("Ошибка при входе:", error);
        });
    },
    methods: {
        updateProgress(value) {
            this.progress = value;
        }
    }
};
</script>

