<template>

    <div class="likes__header">
        <p>{{ $t('shop.likes.title') }}</p>
        <span>{{ $t('shop.profit_per_5_min') }}</span>
    </div>

    <div class="likes__list">

        <div class="likes__item"
            v-for="(item, index) in getLikeItems"
            :key="index"
        >
            <div class="icon">
                <img :src="require(`@/assets/images/shop/likes/item-${index + 1}.png`)" alt="">
            </div>

            <div class="info">

                <p>{{ item.amount }}</p>

                <span>LIKES</span>

                <button class="primary__btn" @click="buyLikes(item.amount, index)">
                    <img v-if="isLoading[index]" class="loader" src="~@/assets/images/icons/loader.svg" >
                    <div v-else> 
                        {{ item.price }} 
                        <span class="text"><img src="~@/assets/images/icons/star.svg" alt=""></span>
                    </div>
                </button>

            </div>

        </div>

    </div>

</template>

<script>
import '@/styles/components/LikesModal.scss';
import { mapGetters, mapActions } from 'vuex';
import ApiService from '@/plugins/ApiService';

export default {
    name: 'LikesModal',
    data() {
        return {
            isLoading: {},
        };
    },
    computed: {
        ...mapGetters("shop", ["getLikeItems"]),
    },
    methods: {
        ...mapActions("shop", ["fetchLikeItems"]),

        async buyLikes(amount, index) {
            if (this.isLoading[index]) return;
            this.isLoading[index] = true; 

            try {
                const response = await ApiService.post("/user/make-deposit", { amount });

                if (response.data.status === "success") {
                    this.$store.dispatch("auth/fetchUserStat");
                    this.$modal.show({
                        type: 'center',
                        title: this.$t('shop.likes.buy_likes'),
                        message: this.$t('shop.likes.payment'),
                        icon: 'success',
                        hasAction: true,
                        actionText: this.$t('shop.modal.to_go'),
                        callback: () => {
                            this.$openLink(response.data.data.url)
                        }
                    });
                    
                } else {
                    this.$modal.show({
                        type: 'center',
                        title: this.$t('shop.modal.error'),
                        message: await this.$translateText(response.data.data.message, this.$i18n.locale),
                        icon: 'error',
                        hasAction: true,
                        actionText: $t('shop.modal.close')
                    });
                }
            } catch (error) {
                this.$modal.show({
                    type: 'center',
                    title: this.$t('shop.modal.error'),
                    message: error,
                    icon: 'error',
                    hasAction: true,
                    actionText: this.$t('shop.modal.close')
                });
            } finally {
                this.isLoading[index] = false; 
            }
        }
    },
    mounted() {
        this.fetchLikeItems();
    }
}
</script>
