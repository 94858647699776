<template>

    <div class="page__info">
        <p class="page__title">{{ $t('tasks.title') }}</p>
        <p class="page__span">{{ $t('tasks.span') }}</p>
    </div>

    <div 
        class="task__list" 
        v-for="task in sortedTasks" 
        :key="task.id"
    >

        <button 
            class="task__item" 
            @click="handleTaskClick(task)"
        >

            <div class="left">

                <div class="task__icon">
                    <img :src="getTaskIcon(task.icon)" alt="" onerror="this.style.display='none';">
                </div>

                <div class="info">

                    <p class="task__name">{{ task.title }}</p>

                    <p class="task__reward">

                        <div class="icon">
                            <img src="~@/assets/images/icons/heart.svg" alt="">
                        </div>

                        <span>+{{ task.executor_reward }} {{ $t('tasks.likes') }}</span>

                    </p>

                </div>

            </div>

            <div class="right">

                <div class="task__status">

                    <button 
                        v-if="task.is_started && !task.is_finished" 
                        class="reward__btn"
                        @click.stop="checkTaskStatus(task)"
                        :disabled="isLoading[task.id]"
                    >
                        <img v-if="isLoading[task.id]" class="loader" src="~@/assets/images/icons/loader.svg" >
                        <span v-else>{{ $t('tasks.get') }}</span>

                    </button>

                    <div class="icon" v-else>
                        <img :src="getStatusIcon(task)">
                    </div>

                </div>

            </div>

        </button>

    </div>

</template>

<script>
import config from '@/config';
import ApiService from '@/plugins/ApiService';
import '@/styles/pages/TaskPage.scss';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
    name: 'TaskPage',
    data(){
        return {
            isLoading: {}
        }
    },
    computed: {
        ...mapGetters("tasks", ["sortedTasks"]),
        ...mapState({
            wallet: state => state.auth.stats.wallet,
        }),
        hasWallet() {
            return !!this.wallet;
        },
    },
    methods: {
        ...mapActions("tasks", ["processTask", "fetchTasks"]),
        getStatusIcon(task) {
            if (!task.is_started && !task.is_finished) {
                return require("@/assets/images/icons/arrow-right.svg");
            }
            if (task.is_started && !task.is_finished) {
                return require("@/assets/images/icons/checked.svg");
            }
            return require("@/assets/images/icons/checked.svg");
        },
        getTaskIcon(icon) {
            return config.ASSET_URL + icon;
        },
        async handleTaskClick(task) {
            if (task.is_finished) return;

            const taskHandlers = {
                3: async () => {
                    if (!this.hasWallet) {
                        this.$modal.show({
                            type: 'center',
                            title: this.$t('bonus.modal.wallet_required.title'),
                            message: this.$t('tasks.modal.message.wallet'),
                            icon: 'error',
                            hasAction: true,
                            actionText: this.$t('bonus.modal.wallet_required.action'),
                            callback: () => {
                                this.$router.push('/wallet');
                            }
                        });
                        return;
                    }

                    await this.processTask({ task, isStart: true });
                    
                    const { amount, wallet } = task.params;
                    const txData = {
                        validUntil: Math.round(Date.now() / 1000) + 600,
                        messages: [{ address: wallet, amount: this.convertTonAmount(amount).toString() }],
                    };

                    try {
                        const transactionResult = await this.$tonConnectUI.sendTransaction(txData);
                        if (transactionResult) {
                            await this.processTransaction(task.id, transactionResult, amount);
                        }
                    } catch (error) {
                        console.error("Ошибка при отправке транзакции:", error);
                        this.$modal.show({
                            type: "center",
                            title: this.$t('tasks.modal.title.error'),
                            message: this.$t('tasks.modal.message.failed_payment'),
                            icon: "error",
                            hasAction: true,
                            actionText: this.$t('tasks.modal.actions.close')
                        });
                    }
                },
                4: async () => {
                    if (!task.is_started) {
                        const response = await this.processTask({ task, isStart: true });
                        const paymentLink = response?.data?.additional_info?.payment_link;
                        if (paymentLink) {
                            this.openLink(paymentLink);
                        }
                    }
                },
                5: async () => {
                    await this.processTask({ task, isStart: true });
                    const referralLink = `${config.BOT_URL}?start=${this.$store.state.auth.user?.id}`;
                    this.$modal.show({
                        type: "center",
                        title: this.$t("tasks.modal.title.ref"),
                        message: `
                            <p>${referralLink}</p>
                        `,
                        icon: "success",
                        hasAction: true,
                        actionText: this.$t("tasks.modal.actions.copy"),
                        callback: () => {
                            this.$copyToClipboard(referralLink);
                        }
                    });
                }
            };

            if (taskHandlers[task.type]) {
                await taskHandlers[task.type]();
            } else {
                if (!task.is_started) {
                    await this.processTask({ task, isStart: true });
                }
                this.openLink(task.params?.url);
            }
        },
        openLink(url) {
            if (url) {
                this.$openLink(url);
            }
        },
        async checkTaskStatus(task) {
            if (this.isLoading[task.id]) return;

            this.isLoading = { ...this.isLoading, [task.id]: true };

            try {
                const response = await this.processTask({ task, isStart: false });

                if (response.data.success) {
                    this.$modal.show({
                        type: "center",
                        title: this.$t('tasks.modal.title.completed'),
                        message: '+' + task.executor_reward + 
                            `<div class="icon">
                                <img src="${require('@/assets/images/icons/likes.svg')}" alt="">
                            </div>`,
                        icon: 'success',
                        hasAction: true,
                        actionText: this.$t('tasks.modal.actions.continue')
                    });

                    await this.fetchTasks();
                    await this.$store.dispatch("auth/fetchUserStat");

                } else {
                    this.$modal.show({
                        type: "center",
                        title: this.$t('tasks.modal.title.error'),
                        message: await this.$translateText(response.data.message, this.$i18n.locale) || this.$t('tasks.modal.message.task_in_processing'),
                        icon: 'error',
                        hasAction: true,
                        actionText: this.$t('tasks.modal.actions.close')
                    });
                }

            } catch (error) {
                console.error("Ошибка при обработке задания:", error);
                this.$modal.show({
                    type: "center",
                    title: this.$t('tasks.modal.title.error'),
                    message: error,
                    icon: 'error',
                    hasAction: true,
                    actionText: this.$t('tasks.modal.actions.close')
                });
            } finally {
                const { [task.id]: _, ...rest } = this.isLoading;
                this.isLoading = rest;
            }
        },
        async processTransaction(taskId, transactionData, amount) {
            try {
                const response = await ApiService.post('/payment/make', {
                    task_id: taskId,
                    transactionData,
                    status: 'success',
                    amount,
                });

                if (response.data.data.success) {
                    this.$modal.show({
                        type: "center",
                        title: this.$t('tasks.modal.title.success_payment'),
                        message: this.$t('tasks.modal.message.success_payment'),
                        icon: "success",
                        hasAction: true,
                        actionText: this.$t('tasks.modal.actions.continue')
                    });
                    await this.fetchTasks();
                } else {
                    throw new Error(response.data.data.message);
                }
            } catch (error) {
                console.error("Ошибка при обработке платежа:", error);
                this.$modal.show({
                    type: "center",
                    title: this.$t('tasks.modal.title.error'),
                    message: this.$t('tasks.modal.message.failed_payment'),
                    icon: "error",
                    hasAction: true,
                    actionText: this.$t('tasks.modal.actions.close')
                });
            }
        },
        convertTonAmount(amount) {
            return parseFloat(amount) * 1e9;
        },
    }
};
</script>
