import ApiService from "@/plugins/ApiService";

export default {
    namespaced: true,
    state: {
        tasks: []
    },
    mutations: {
        SET_TASKS(state, tasks) {
            state.tasks = tasks;
        },
        UPDATE_TASK(state, updatedTask) {
            const tasks = state.tasks.map(task =>
                task.id === updatedTask.id ? { ...updatedTask } : task
            );
            state.tasks = tasks;
        }
    },
    actions: {
        async fetchTasks({ commit }) {
            try {
                const response = await ApiService.get("/task/list");
                if (response.data.status === "success") {
                    commit("SET_TASKS", response.data.data);
                }
            } catch (error) {
                console.error("Ошибка при получении списка заданий:", error);
            }
        },
        async processTask({ commit, state }, { task, isStart = false }) {
            try {
                const response = await ApiService.post("/task/process", {
                    id: task.id,
                    is_start: isStart
                });

                if (response.data.data.success) {
                    const updatedTask = {
                        ...task,
                        is_started: true
                    };

                    if (!isStart) {
                        updatedTask.is_finished = response.data.data.success;
                    }

                    commit("UPDATE_TASK", updatedTask);
                }

                return response.data;
            } catch (error) {
                console.error("Ошибка при обработке задания:", error);
            }
        }
    },
    getters: {
        sortedTasks: (state) => {
            return [...state.tasks].sort((a, b) => {
                if (a.is_started && !a.is_finished) return -2;
                if (!a.is_started && !a.is_finished) return -1;
                if (a.is_started && a.is_finished) return 0;
                return 1;
            });
        }
    }
};
