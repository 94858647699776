import { createRouter, createWebHashHistory } from "vue-router";
import MainPage from "@/views/MainPage.vue";
import FriendsPage from "@/views/FriendsPage.vue";
import TaskPage from "@/views/TaskPage.vue";
import WalletPage from "@/views/WalletPage.vue";
import ShopPage from "@/views/ShopPage.vue";
import RankingPage from "@/views/RankingPage.vue";
import BonusPage from "@/views/BonusPage.vue";
import LoadingPage from "@/views/LoadingPage.vue";

const routes = [
    { path: "/", name: "Main", component: MainPage },
    { path: "/shop", name: "Shop", component: ShopPage },
    { path: "/task", name: "Task", component: TaskPage },
    { path: "/friends", name: "Friends", component: FriendsPage },
    { path: "/wallet", name: "Wallet", component: WalletPage },
    { path: "/ranking", name: "Ranking", component: RankingPage, meta: { fullScreen: true } },
    { path: "/bonus", name: "Bonus", component: BonusPage, meta: { fullScreen: true } },
    { path: "/loading", name: "LoadingPage", component: LoadingPage, meta: { fullScreen: true } }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
