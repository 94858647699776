export default {
    install(app) {
        app.config.globalProperties.$openLink = function (url) {
            const isIOS = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints > 0;

            if (isIOS) {
                window.location.href = url;
            } else {
                window.open(url, '_blank');
            }
        };
        app.config.globalProperties.$copyToClipboard = function (text) {
            navigator.clipboard.writeText(text)
        };
        app.config.globalProperties.$translateText = async (text, targetLang = "en") => {
            try {
                const sourceLang = "auto";
                const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${sourceLang}&tl=${targetLang}&dt=t&q=${encodeURIComponent(text)}`;

                const response = await fetch(url);
                const data = await response.json();

                return data[0].map(item => item[0]).join("") || text;
            } catch (error) {
                console.error("Ошибка перевода:", error);
                return text;
            }
        };
    }
};
