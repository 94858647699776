import { createStore } from "vuex";
import auth from "./auth";
import referal from "./referal";
import ranking from "./ranking";
import shop from "./shop";
import tasks from "./tasks";

export default createStore({
    modules: {
        auth,
        referal,
        ranking,
        shop,
        tasks
    },
});
