<template>

    <div class="withdraw__form">

        <div class="balance">
            
            <p class="title">{{ $t('wallet.form.balance_title') }}</p>

            <div class="value">

                <div class="icon">
                    <img src="~@/assets/images/icons/mt-coin-l.svg" alt="">
                </div>

                <span>{{ formattedViews }}</span>

            </div>

            <p class="currency">100 $MTUBE = 1 MTT</p>

        </div>

        <div class="withdraw__input">

            <div class="input">

                <div class="left">

                    <img src="~@/assets/images/icons/mt-coin-l.svg" alt="">

                    <input
                        type="number"
                        placeholder="0.1"
                        step="0.1"
                        v-model="withdrawAmount"
                        @input="validateAmount"
                    />

                </div>

                <div class="right">
                    <img v-if="showWarning" class="warn__input" src="~@/assets/images/icons/warn.svg" alt="" />
                    <img v-if="withdrawAmount" class="clear__input" src="~@/assets/images/icons/clear.svg" alt="" @click="clearInput" />
                    <button class="primary__btn" @click="setMaxAmount">Max</button>
                </div>

            </div>

            <p class="min">{{ $t('wallet.form.withdraw_minimum', { value: '1 000 000' }) }}</p>
        </div>

        <button class="primary__btn withdraw--btn" :disabled="!isValidAmount">{{ $t('wallet.form.withdraw_button') }}</button>

        <p class="time">{{ $t('wallet.form.withdraw_time') }}</p>

    </div>

    <div class="wallet__connect">

        <p class="title">{{ $t('wallet.wallet_info.title') }}</p>

        <button v-if="!isConnected" class="primary__btn" @click="connectWallet">
            {{ $t('wallet.wallet_info.connect_button') }}
        </button>

        <button v-else class="primary__btn connected" @click="showWalletModal = true">

            <span>{{ maskedWallet }}</span>

            <div class="icon">
                <img src="~@/assets/images/icons/link.svg" alt="">
            </div>

        </button>

        <Modal v-if="showWalletModal" @close="showWalletModal = false" type="bottom">

            <div class="wallet__info--modal">

                <div class="wallet__icon">
                    <img :src="walletInfo?.imageUrl || '/default-wallet-icon.png'" alt="TON" />
                </div>

                <p class="page__title">{{ $t('wallet.wallet_info.wallet_modal_title') }}</p>

                <div class="wallet__reward" @click="copyWalletAddress">

                    <span>{{ maskedWallet }}</span>

                    <div class="icon">
                        <SvgIcon v-if="!copied" :src="require('@/assets/images/icons/copy.svg')" />
                        <SvgIcon v-else :src="require('@/assets/images/icons/checked.svg')" />
                    </div>
                    
                </div>

            </div>

            <button class="primary__btn disc" @click="disconnectWallet">{{ $t('wallet.wallet_info.disconnect_wallet_button') }}</button>

        </Modal>

    </div>

    <div class="withdraw__history">

        <p class="page__title">{{ $t('wallet.history.page_title') }}</p>

        <div
            v-for="(item, index) in history"
            :key="index"
            class="history__item"
        >

            <div class="row">
                <span class="bold">{{ item.type }}</span>
                <span class="large">{{ item.type === $t("wallet.history.type.out") ? '-' : '' }}{{ item.amount }} $MTUBE</span>
            </div>

            <div class="row">
                <span>{{ formatDate(item.created_at) }}</span>
                <span>{{ item.status }}</span>
                <span>{{ formatTime(item.created_at) }}</span>
            </div>

        </div>

    </div>

</template>

<script>
import '@/styles/pages/WalletPage.scss';
import SvgIcon from './components/SvgIcon.vue';
import { mapState } from 'vuex';

export default {
    name: 'Wallet',
    components:{
        SvgIcon
    },
    data() {
        return {
            isConnected: false,
            withdrawAmount: "",
            minWithdraw: 1000000,
            history: [
                { amount: 10000, type: this.$t("wallet.history.type.out"), created_at: '12-02-2025 12:21', status: this.$t("wallet.history.status.success")},
                { amount: 10000, type: this.$t("wallet.history.type.in"), created_at: '12-02-2025 12:21', status: this.$t("wallet.history.status.processing")},
                { amount: 10000, type: this.$t("wallet.history.type.out"), created_at: '12-02-2025 12:21', status: this.$t("wallet.history.status.cancel")}
            ],
            wallet: null,
            showWalletModal: false,
            copied: false
        };
    },
    computed: {
        ...mapState('auth', ['stats']),
        formattedViews() {
            return this.stats.views.toLocaleString('ru-RU');
        },
        isConnected() {
            return !!this.wallet;
        },
        maskedWallet() {
            return this.$walletPublicFormat(this.wallet) ? `${this.$walletPublicFormat(this.wallet).slice(0, 8)}...${this.$walletPublicFormat(this.wallet).slice(-8)}` : "";
        },
        isValidAmount() {
            return this.withdrawAmount >= this.minWithdraw;
        },
        showWarning() {
            return this.withdrawAmount && this.withdrawAmount < this.minWithdraw;
        },
        walletInfo() {
            return this.$tonConnectUI.walletInfo || {};
        }
    },
    methods: {
        async connectWallet() {
            /* this.isConnected = true;
            this.wallet = this.$tonConnect.account?.address;
            console.log("Кошелек подключен:", this.walletAddress); */

            try{
                await this.$connectWallet();
            } catch(error) {
                console.log(error)
            } finally {
                this.wallet = this.$tonConnect.account?.address;
            }
        },
        async copyWalletAddress() {
            if (!this.wallet) return;

            try {
                await navigator.clipboard.writeText(this.$walletPublicFormat(this.wallet));
                this.copied = true;
                
                setTimeout(() => {
                    this.copied = false;
                }, 2000);
            } catch (err) {
                console.error("Ошибка копирования:", err);
            }
        },
        async disconnectWallet() {
            await this.$disconnectWallet();
            this.wallet = null;
            this.showWalletModal = false;
        },
        formatDate(dateString) {
            const options = { day: "2-digit", month: "2-digit", year: "numeric" };
            return new Date(dateString).toLocaleDateString("ru-RU", options);
        },
        formatTime(dateString) {
            const options = { hour: "2-digit", minute: "2-digit" };
            return new Date(dateString).toLocaleTimeString("ru-RU", options);
        },
        clearInput() {
            this.withdrawAmount = "";
        },
        setMaxAmount() {
            this.withdrawAmount = 10000000;
            this.validateAmount();
        },
        validateAmount() {
            if (this.withdrawAmount < this.minWithdraw) {
                console.warn("Сумма меньше минимальной!");
            }
        },
    },
    mounted(){
        this.wallet = this.$tonConnect.account?.address || null;
    }
};
</script>