<template>

    <nav class="menu">

        <div class="menu__list">

            <button 
                class="menu__item" 
                v-for="(item, index) in menuItems" 
                :key="index"
                @click="navigateTo(item.path)"
                :class="{ active: isActive(item.path) }"
            >

                <div :class="'icon ' + item.name">
                    <SvgIcon :src="item.icon" customClass="menu__icon" />
                </div>

                <p class="text">{{ item.label }}</p>

            </button>


        </div>

    </nav>

</template>

<script>
import { useRouter, useRoute } from "vue-router";
import SvgIcon from "@/views/components/SvgIcon.vue";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export default {
    name: "Menu",
    components: { SvgIcon },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const i18n = useI18n();

        const menuItems = computed(() => [
            { label: i18n.t("menu.shop"), icon: require("@/assets/images/menu/shop.svg"), name: "shop", path: "/shop" },
            { label: i18n.t("menu.friends"), icon: require("@/assets/images/menu/friends.svg"), name: "friends", path: "/friends" },
            { label: i18n.t("menu.main"), icon: require("@/assets/images/menu/main.svg"), name: "main", path: "/" },
            { label: i18n.t("menu.tasks"), icon: require("@/assets/images/menu/task.svg"), name: "task", path: "/task" },
            { label: i18n.t("menu.wallet"), icon: require("@/assets/images/menu/wallet.svg"), name: "wallet", path: "/wallet" }
        ]);

        const navigateTo = (path) => {
            router.push(path);
        };

        const isActive = (path) => {
            return route.path === path;
        };

        return { menuItems, navigateTo, isActive };
    }
};
</script>