function importAllImages(context) {
    const images = {};
    context.keys().forEach((key) => {
        const imageName = key.replace("./", "");
        images[imageName] = context(key);
    });
    return images;
}

const loadImages = importAllImages(require.context("@/assets/images", true, /\.(png|jpe?g|svg|webp)$/));

export default loadImages;
