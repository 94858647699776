<template>

    <div v-if="route.path === '/loading'">
        <router-view />
    </div>

    <div 
        v-else 
        class="wrapper"
        v-motion
        :initial="{ opacity: 0, y: -20 }"
        :enter="{ opacity: 1, y: 0, transition: { duration: 0.5 } }"
    >

        <Header v-if="!route.meta.fullScreen" :routePath="route.path" />

        <main class="page__content" :class="{ 'no-pad': route.meta.fullScreen }">
            <router-view></router-view>
        </main>

        <Modal
            v-for="modal in modalState.modals"
            :key="modal.id"
            :visible="true"
            :type="modal.type"
            :title="modal.title"
            :message="modal.message"
            :icon="modal.icon"
            :hasAction="modal.hasAction"
            :actionText="modal.actionText"
            :callback="modal.callback"
            @close="hideModal(modal.id)"
            @action="handleAction(modal)"
            >

            <template #default>
                <component :is="modal.content" />
            </template>

        </Modal>

        <Menu v-if="!route.meta.fullScreen" />

    </div>

</template>

<script>
import { inject, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/views/components/Header.vue";
import Menu from "@/views/components/Menu.vue";
import LoadingPage from "@/views/LoadingPage.vue";

export default {
    name: "App",
    components: { 
        Header, 
        Menu
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        const navigateTo = (path) => {
            router.push(path);
        };

        const isActive = (path) => {
            return route.path === path;
        };

        const modalState = inject("modalState");

        const hideModal = (id) => {
            modalState.modals = modalState.modals.filter(modal => modal.id !== id);
        };

        const handleAction = (modal) => {
            if (modal.callback) {
                modal.callback();
            }
            hideModal(modal.id);
        };

        const routePage = (page) => {
            return router.push(page);
        }

        return { navigateTo, isActive, route, modalState, hideModal, handleAction, routePage };
    },
};
</script>
