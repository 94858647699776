<template>

    <div class="shop__list">

        <button class="shop__item" @click="openModal('upgrade')">

            <p class="name">{{ $t('shop.upgrade.title') }}</p>

            <div class="icon">
                <img src="~@/assets/images/shop/item-1.png" alt="">
            </div>

        </button>

        <button class="shop__item" @click="openModal('likes')">

            <p class="name">{{ $t('shop.likes.title') }}</p>

            <div class="icon">
                <img src="~@/assets/images/shop/item-2.png" alt="">
            </div>

        </button>

    </div>

    <Modal v-if="activeModal === 'upgrade'" type="bottom" @close="closeModal">
        <UpgradeModal />
    </Modal>

    <Modal v-if="activeModal === 'likes'" type="bottom" @close="closeModal">
        <LikesModal />
    </Modal>

</template>

<script>
import '@/styles/pages/ShopPage.scss';
import UpgradeModal from './components/UpgradeModal.vue';
import LikesModal from './components/LikesModal.vue';

export default {
    name: 'Shop',
    components: { 
        UpgradeModal,
        LikesModal
    },
    data() {
        return {
            activeModal: null,
        };
    },
    methods: {
        openModal(type) {
            this.activeModal = type;
        },
        closeModal() {
            this.activeModal = null;
        }
    }
};
</script>