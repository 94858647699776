import store from "@/store";
import loadImages from "@/utils/loadImages";
import WebApp from "@twa-dev/sdk";
import { useWebAppTheme } from 'vue-tg'

WebApp.expand();
WebApp.ready();

const { setBackgroundColor, setHeaderColor } = useWebAppTheme();


/* setBackgroundColor('#FF7373');
setHeaderColor('#FF7373');
 */

const isMobile = WebApp.platform === "android" || WebApp.platform === "ios";

if (isMobile) {
    WebApp.requestFullscreen()
    document.documentElement.classList.add("isMobile");
}

const TelegramAuth = {
    async login(updateProgress) {
        try {
            const startTime = performance.now();

            updateProgress(10);
            await new Promise(resolve => setTimeout(resolve, 300));

            const tgWebAppData = WebApp.initData /* "query_id=AAGnqpopAAAAAKeqmikvA9KH&user=%7B%22id%22%3A698002087%2C%22first_name%22%3A%22Andrey%22%2C%22last_name%22%3A%22Dmitruk%22%2C%22username%22%3A%22ka1dos%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FerOT1E06SV02NjPZEc7jfZX3IbERJwLM3afmTFLVQcc.svg%22%7D&auth_date=1742891749&signature=xLjHS6op5SEvbrsALNebMC5dL1tW7E5EFCdCJPMcDbEPGRmMdBJQHtOBUGRbggrXU4TtZpV6L5agTsc6CLP_AA&hash=ffff740f04453db0ed911dc08d159092260b6b95def57f8e666cdbbe46f19b53" */;
            if (!tgWebAppData) {
                console.error("Ошибка: tgWebAppData не найден.");
                return;
            }

            updateProgress(30);

            await store.dispatch("auth/login", {
                user: {
                    id: WebApp.initDataUnsafe.user.id,
                    first_name: WebApp.initDataUnsafe.user.first_name,
                    username: WebApp.initDataUnsafe.user.username,
                    avatar: WebApp.initDataUnsafe.user.photo_url,
                    language_code: WebApp.initDataUnsafe.user.language_code
                },
                raw: tgWebAppData,
            });

            updateProgress(50);
            await Promise.all([
                store.dispatch('referal/fetchReferralData'),
                store.dispatch('ranking/fetchTopUsers'),
                store.dispatch('shop/fetchUpgradeItems'),
                store.dispatch('shop/fetchLikeItems'),
                store.dispatch('tasks/fetchTasks')
            ]);

            updateProgress(80);
            this.preloadImages();

            const endTime = performance.now();
            console.log(`⏱️ TelegramAuth завершён за ${(endTime - startTime).toFixed(2)} мс`);


            updateProgress(100);

            await new Promise(resolve => setTimeout(resolve, 1000));

        } catch (error) {
            console.error("❌ Ошибка авторизации:", error);
        }
    },

    preloadImages() {
        const images = loadImages; 
        console.log("🔄 Предзагружены изображения:", Object.keys(images));

        Object.values(images).forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    }
};

export default TelegramAuth;
