import { TonConnect, toUserFriendlyAddress } from "@tonconnect/sdk";
import { TonConnectUI } from "@tonconnect/ui";
import ApiService from "@/plugins/ApiService";

const tonConnect = new TonConnect({
    manifestUrl: "https://testmt.xyz/assets/manifest/tonconnect-manifest.json",
});

const tonConnectUI = new TonConnectUI({
    manifestUrl: "https://testmt.xyz/assets/manifest/tonconnect-manifest.json",
    
});

const TonConnectPlugin = {
    install(app) {
        app.config.globalProperties.$tonConnect = tonConnect;
        app.config.globalProperties.$tonConnectUI = tonConnectUI;

        (async () => {
            try {
                const restoredUI = await tonConnectUI.connectionRestored;
        
                if (restoredUI) {
                    console.log(
                        "UI Connection restored. Wallet:",
                        JSON.stringify({
                            ...tonConnectUI.wallet,
                            ...tonConnectUI.walletInfo
                        })
                    );
                } else {
                    console.log("UI Connection was not restored.");
                }
        
                await tonConnect.restoreConnection();
                const sdkWallet = tonConnect.account;
        
                if (sdkWallet) {
                    console.log("SDK Connection restored. Wallet:", sdkWallet.address);
                } else {
                    console.log("SDK Connection was not restored.");
                }
            } catch (error) {
                console.error("Ошибка при восстановлении соединения:", error);
            }
        })();
        

        app.config.globalProperties.$connectWallet = async () => {
            await tonConnectUI.connectWallet();
            const walletInfo = tonConnectUI.wallet;
        
            if (walletInfo) {
                await tonConnect.restoreConnection();
                console.log("Кошелек подключен в SDK:", walletInfo.account.address);
        
                try {
                    await ApiService.post("/wallet/save", { wallet_address: walletInfo.account.address });
                    app.config.globalProperties.$store.commit("auth/SET_STATS", { wallet: walletInfo.account.address });
                } catch (error) {
                    console.error("Ошибка сохранения кошелька:", error);
                }
            }
        };
        

        app.config.globalProperties.$disconnectWallet = async () => {
            try {
                await ApiService.post("/wallet/save", { wallet_address: null });
                app.config.globalProperties.$store.commit("auth/SET_STATS", { wallet: null });
                tonConnect.disconnect();
                tonConnectUI.disconnect();
            } catch (error) {
                console.error("Ошибка при отключении кошелька:", error);
            }
        };

        app.config.globalProperties.$walletPublicFormat = (address) => {
            return toUserFriendlyAddress(address);
        };        

        tonConnect.onStatusChange(async (walletInfo) => {
            if (walletInfo) {
                try {
                    await ApiService.post("/wallet/save", { wallet_address: walletInfo.account.address });
                    app.config.globalProperties.$store.commit("auth/SET_STATS", { wallet: walletInfo.account.address });
                } catch (error) {
                    console.error("Ошибка сохранения кошелька:", error);
                }
            }
        });

        window.tonConnect = tonConnect;
        window.tonConnectUI = tonConnectUI;
    }
};

export default TonConnectPlugin;
