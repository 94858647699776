import ApiService from "@/plugins/ApiService";

export default {
    namespaced: true,
    state: {
        upgradeItems: [],
        likeItems: [],
    },
    mutations: {
        SET_UPGRADE_ITEMS(state, items) {
            state.upgradeItems = items;
        },
        SET_LIKE_ITEMS(state, items) {
            state.likeItems = items;
        }
    },
    actions: {
        async fetchUpgradeItems({ commit }) {
            try {
                const response = await ApiService.get("/channel/list-upgrade");
                if (response.data.status === "success") {
                    commit("SET_UPGRADE_ITEMS", response.data.data);
                }
            } catch (error) {
                console.error("Ошибка загрузки списка улучшений:", error);
            }
        },
        async fetchLikeItems({ commit }) {
            try {
                const response = await ApiService.get("/payment/list");
                if (response.data) {
                    commit("SET_LIKE_ITEMS", response.data.data);
                }
            } catch (error) {
                console.error("Ошибка загрузки списка лайков:", error);
            }
        }
    },
    getters: {
        getUpgradeItems: (state) => {
            return Object.entries(state.upgradeItems).map(([price, amount]) => ({
                price: Number(price),
                amount: Number(amount)
            }));
        },
        getLikeItems: (state) => {
            return Object.entries(state.likeItems).map(([amount, price]) => ({
                amount: Number(amount),
                price: Number(price),
            }));
        },
    }
};