<template>

    <div class="upgrade__header">
        <p>{{ $t('shop.upgrade.title') }}</p>
        <span>{{ $t('shop.profit_per_5_min') }}</span>
    </div>

    <div class="upgrade__list">

        <div class="upgrade__item"
            v-for="(item, index) in getUpgradeItems"
            :key="index"
        >

            <p>{{ item.amount / 10 }}</p>
    
            <span>$MTUBE</span>

            <button class="primary__btn" @click="buyUpgrade(item.price, item.amount, index)">
                <img v-if="isLoading[index]" class="loader" src="~@/assets/images/icons/loader.svg" >
                <div v-else> 
                    {{ item.price }}
                    <span class="text"><img src="~@/assets/images/icons/heart.svg" alt=""></span>
                </div>
            </button>

        </div>

    </div>

</template>

<script>
import ApiService from '@/plugins/ApiService';
import '@/styles/components/UpgradeModal.scss';
import { mapActions, mapState, mapGetters } from 'vuex';

export default{
    name: 'UpgradeModal',
    data() {
        return {
            isLoading: {},
        };
    },
    computed: {
        ...mapState("shop", ["upgradeItems"]),
        ...mapGetters("shop", ["getUpgradeItems"]),
    },
    methods: {
        ...mapActions("shop", ["fetchUpgradeItems"]),
        
        async buyUpgrade(price, amount, index) {
            if (this.isLoading[index]) return;
            this.isLoading[index] = true;
            
            try {
                const response = await ApiService.post("/channel/upgrade", { price });
                if (response.data.status === "success") {
                    this.$store.dispatch("auth/fetchUserStat");
                    this.$modal.show({
                        type: 'center',
                        title: this.$t('shop.upgrade.upgrade_account'),
                        message: '+' + amount / 10 + ' $MTUBE',
                        icon: 'success',
                        hasAction: true,
                        actionText: this.$t('shop.modal.continue')
                    });
                } else {
                    this.$modal.show({
                        type: 'center',
                        title: this.$t('shop.modal.error'),
                        message: await this.$translateText(response.data.data.message, this.$i18n.locale),
                        icon: 'error',
                        hasAction: true,
                        actionText: this.$t('shop.modal.close')
                    });
                }
            } catch (error) {
                this.$modal.show({
                    type: 'center',
                    title: $t('shop.modal.error'),
                    message: error,
                    icon: 'error',
                    hasAction: true,
                    actionText: this.$t('shop.modal.close')
                });
            } finally {
                this.isLoading[index] = false;
            }
        },
    },
}
</script>