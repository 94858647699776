import { reactive } from "vue";
import Modal from "@/views/components/Modal.vue";

export default {
    install(app) {
        const state = reactive({
            modals: [],
        });

        app.component("Modal", Modal);

        app.config.globalProperties.$modal = {
            show(options) {
                if (options.icon && typeof options.icon === "string") {
                    options.icon = require(`@/assets/images/modal/${options.icon}.png`);
                }

                state.modals = state.modals.filter((modal) => modal.type !== options.type);

                state.modals.push({
                    ...options,
                    id: Date.now(),
                });
            },
            hide(id) {
                state.modals = state.modals.filter((modal) => modal.id !== id);
            },
            hideAll() {
                state.modals = [];
            },
        };

        app.provide("modalState", state);
    },
};
