<template>

    <div class="page__info center">

        <div class="page__title">

            <button class="back" @click="$router.back()">
                <img src="~@/assets/images/icons/arrow-left.svg" alt="">
            </button>

            <span>{{ $t('bonus.title') }}</span>

        </div>

        <p class="page__span">{{ $t('bonus.description') }}</p>

    </div>

    <div class="bonus__timer --inner">
        {{ $t('bonus.timer') }} <span>{{ formattedTime }}</span>
    </div>

    <div class="bonus__image">
        <img :src="bonusAvailable ? require('@/assets/images/bonus/bonus.png') : require('@/assets/images/bonus/bonus-claimed.png')" alt="">
    </div>

    <button class="primary__btn bonus" :disabled="!bonusAvailable" @click="claimBonus">
        <img v-if="isLoading" class="loader" src="~@/assets/images/icons/loader.svg" >
        <span v-else>{{ bonusAvailable ? $t('bonus.button.claim') : $t('bonus.button.claimed') }}</span>
    </button>

</template>

<script>
import '@/styles/pages/BonusPage.scss';
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ApiService from '@/plugins/ApiService';

dayjs.extend(utc);

export default {
    name: 'Bonus',
    data() {
        return {
            timeRemaining: 0,
            bonusAvailable: false,
            timer: null,
            isLoading: false
        };
    },
    computed: {
        ...mapState({
            lastBonusDate: state => state.auth.stats.last_bonus_date,
            wallet: state => state.auth.stats.wallet,
            admin_channel: state => state.auth.stats.admin_channel
        }),
        hasWallet() {
            return !!this.wallet;
        },
        formattedTime() {
            const hours = Math.floor(this.timeRemaining / 3600);
            const minutes = Math.floor((this.timeRemaining % 3600) / 60);
            const seconds = this.timeRemaining % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }
    },
    methods: {
        calculateTime() {
            if (!this.lastBonusDate) {
                this.bonusAvailable = true;
                return;
            }

            const lastBonusUTC = dayjs.utc(this.lastBonusDate);
            const nextResetUTC = lastBonusUTC.startOf('day').add(1, 'day');
            const nowUTC = dayjs.utc();

            if (nowUTC.isAfter(nextResetUTC)) {
                this.bonusAvailable = true;
                this.timeRemaining = 0;
            } else {
                this.bonusAvailable = false;
                this.timeRemaining = nextResetUTC.diff(nowUTC, 'second');
                this.startTimer();
            }
        },
        startTimer() {
            if (this.timer) clearInterval(this.timer);
            this.timer = setInterval(() => {
                if (this.timeRemaining > 0) {
                    this.timeRemaining--;
                } else {
                    this.bonusAvailable = true;
                    clearInterval(this.timer);
                }
            }, 1000);
        },
        async claimBonus() {
            if (this.bonusAvailable) {

                if (this.isLoading) return;
                this.isLoading = true;

                if (!this.hasWallet) {
                    this.$modal.show({
                        type: 'center',
                        title: this.$t('bonus.modal.wallet_required.title'),
                        message: this.$t('bonus.modal.wallet_required.message'),
                        icon: 'error',
                        hasAction: true,
                        actionText: this.$t('bonus.modal.wallet_required.action'),
                        callback: () => {
                            this.$router.push('/wallet');
                        }
                    });
                    return;
                }

                try {
                    const response = await ApiService.post("/user/get-bonus");

                    if (response.data.status === "success") {
                        this.$store.dispatch("auth/fetchUserStat");
                        this.$modal.show({
                            type: 'center',
                            title: this.$t('bonus.modal.success.title'),
                            message: '+' + response.data.data.claimed + 
                            `
                                <div class="icon">
                                    <img src="${require('@/assets/images/icons/likes.svg')}" alt="">
                                </div>
                            `,
                            icon: 'success',
                            hasAction: true,
                            actionText: this.$t('bonus.modal.success.action')
                        });

                        this.$store.dispatch('auth/fetchUserStat'); 
                        this.bonusAvailable = false;
                        this.calculateTime();
                    } else {
                        this.$modal.show({
                            type: 'center',
                            title: this.$t('bonus.modal.error.title'),
                            message: await this.$translateText(response.data.data.message, this.$i18n.locale),
                            icon: 'error',
                            hasAction: true,
                            actionText: response.data.data.message === "Вы не подписаны на канал" ? this.$t('bonus.modal.error.action_subscribe') : this.$t('bonus.modal.error.action_close'),
                            callback: () => {
                                if (response.data.data.message === "Вы не подписаны на канал") {
                                    this.$openLink(this.admin_channel); 
                                }
                            }

                        });
                    }
                } catch (error) {
                    this.$modal.show({
                        type: 'center',
                        title: this.$t('bonus.modal.error.title'),
                        message: error,
                        icon: 'error',
                        hasAction: true,
                        actionText: this.$t('bonus.modal.error.action_close'),
                    });
                } finally {
                    this.isLoading = false;
                }
            }
        }
    },
    watch: {
        lastBonusDate: {
            immediate: true,
            handler() {
                this.calculateTime();
            }
        }
    },
    beforeUnmount() {
        clearInterval(this.timer);
    }
};
</script>
