import config from "@/config";
import axios from "axios";

const api = axios.create({
    baseURL: config.BASE_URL,
    timeout: 10000,
});


api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        return config;
    },
    (error) => Promise.reject(error)
);

const ApiService = {
    get(resource, params) {
        return api.get(resource, { params });
    },
    post(resource, data) {
        return api.post(resource, data);
    },
    put(resource, data) {
        return api.put(resource, data);
    },
    delete(resource) {
        return api.delete(resource);
    },
};

export default ApiService;
