<template>

    <div :class="['modal', type]" @click.self="close">

        <div class="modal__content" ref="modalContent">

            <button class="modal__close" @click="close">
                <img src="~@/assets/images/icons/close.svg" alt="Закрыть" />
            </button>

            <template v-if="type === 'center'">
                <div class="modal__image" v-if="icon">
                    <img :src="icon" alt="Иконка" />
                </div>

                <p class="title">{{ title }}</p>
                
                <p class="text" v-html="message"></p>

                <button v-if="hasAction" class="primary__btn yel" @click="handleAction">
                    {{ actionText }}
                </button>
            </template>

            <template v-if="type === 'bottom'">
                <slot></slot>
            </template>

        </div>

    </div>

</template>

<script>
import anime from 'animejs';
import '@/styles/components/Modal.scss';

export default {
    name: "Modal",
    props: {
        visible: { type: Boolean, default: false },
        type: { type: String, default: "center" },
        title: { type: String, default: "" },
        message: { type: String, default: "" },
        icon: { type: String, default: "" },
        hasAction: { type: Boolean, default: false },
        actionText: { type: String, default: "OK" },
        callback: { type: Function, default: null },
        refModal: { type: Object, default: null }
    },
    emits: ["close", "action"],
    watch: {
        visible(newValue) {
            if (newValue) {
                this.showModal();
            } else {
                this.hideModal();
            }
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        handleAction() {
            if (this.callback) {
                this.callback();
            }
            this.$emit("action");
            this.close();
        },
    }
};
</script>