import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import ru from "@/locales/ru.json";

const messages = {
    en,
    ru,
};

const userLanguage = localStorage.getItem("language") || "ru";

const Locales = createI18n({
    locale: localStorage.getItem("language") || userLanguage,
    fallbackLocale: "ru",
    messages,
});


export default Locales;