<template>
    <div class="page__info">
        <p class="page__title">{{ $t('friends.title') }}</p>
        <p class="page__span">{{ $t('friends.span') }}</p>
    </div>

    <div 
        class="referal__level__list" 
        v-for="(item, index) in updateReferNum" 
        :key="index"
    >
        <button class="referal__level__item" @click="showReferralList(item.level)">

            <p class="text">{{ $t('friends.level', { value: item.level }) }} <span>({{ item.percent }}%)</span></p>

            <div class="right">

                <span>{{ item.refer }}</span>

                <div class="icon">
                    <img src="~@/assets/images/icons/people.svg" alt="">
                </div>

            </div>

        </button>

    </div>

    <div class="friends__btn">

        <button class="primary__btn" @click="inviteToTelegram">{{ $t('friends.invite') }}</button>

        <button class="primary__btn" @click="copyToClipboard">

            <div class="icon">
                <img src="~@/assets/images/icons/copy.svg" alt="">
            </div>

        </button>

    </div>

    <Modal type="bottom" v-if="modalLevel" @close="closeModal">

        <p class="page__title --ref">{{ $t('friends.level', { value: modalLevel }) }}</p>

        <div class="referal__list">

            <div v-if="referralDataByLevel[modalLevel] && referralDataByLevel[modalLevel].length > 0">

                <div v-for="(referral, index) in referralDataByLevel[modalLevel]" :key="index" class="referal__list__item">
                    <p class="username">{{ referral.username }}</p>
                    <p class="date">{{ referral.date_created }}</p>
                </div>

            </div>

            <div v-else>
                <p style="text-align: center;">{{ $t('friends.modal.no_refer') }}</p>
            </div>

        </div>

    </Modal>

</template>

<script>
import { mapState, mapActions } from "vuex";
import '@/styles/pages/FriendsPage.scss';

export default {
    name: 'Friends',
    data() {
        return {
            referalLevelItems: [
                { level: 1, percent: 5, refer: 0 },
                { level: 2, percent: 4, refer: 0 },
                { level: 3, percent: 3, refer: 0 },
                { level: 4, percent: 2, refer: 0 },
                { level: 5, percent: 1, refer: 0 },
            ],
            modalLevel: null, 
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("referal", ["referralLevels", "referralData"]),

        referralDataByLevel() {
            const levels = {};
            for (let level = 1; level <= 5; level++) {
                const levelData = this.referralLevels?.[level]?.rows || [];
                levels[level] = levelData;
            }
            return levels;
        },

        updateReferNum() {
            return this.referalLevelItems.map(item => {
                const referralData = this.referralData?.levels?.[item.level] || 0;
                item.refer = referralData;
                return item;
            });
        }
    },
    methods: {
        ...mapActions("referal", ["fetchReferralListByLevel"]),
        showReferralList(level) {
            this.modalLevel = level;
            if (!this.referralDataByLevel[level] || this.referralDataByLevel[level].length === 0) {
                this.fetchReferralListByLevel({ level });
            }
        },
        closeModal() {
            this.modalLevel = null;
        },
        inviteToTelegram() {
            const userid = this.user?.id;
            if (!userid) {
                console.error("User ID not loaded. Cannot create invite link.");
                return;
            }
            const linkToShare = `${config.BOT_URL}?start=${userid}`;
            const url = `https://t.me/share/url?url=${encodeURIComponent(linkToShare)}`;
            window.open(url, '_blank');
        },
        copyToClipboard() {
            const userid = this.user?.id;

            if (!userid) {
                console.error("User ID not loaded. Cannot create invite link.");
                return;
            }

            const linkToShare = `${config.BOT_URL}?start=${userid}`;

            navigator.clipboard.writeText(linkToShare)
                .then(() => {
                    console.log("Link copied to clipboard!");
                    this.$modal.show({
                        type: 'center',
                        title: this.$t('friends.title'),
                        message: this.$t('friends.modal.copy_link'),
                        icon: 'success',
                        hasAction: true,
                        actionText: this.$t('friends.modal.continue')
                    });
                })
                .catch((error) => {
                    console.error("Failed to copy link:", error);
                });
        },
    },
    mounted() {
        for (let level = 1; level <= 5; level++) {
            this.fetchReferralListByLevel({ level });
        }
    }
};
</script>
