import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/main.scss";
import Modal from "./plugins/Modal";
import config from "@/config";
import globalMethods from "./plugins/globalMethods";
import TonConnectPlugin from "./plugins/TonConnect";
import Locales from "./plugins/Locales";
import { MotionPlugin } from "@vueuse/motion";

window.globalState = {};
window.config = config;

const app = createApp(App);

router.replace("/loading").then(() => {
    app.use(router).use(store).use(Modal).use(globalMethods).use(TonConnectPlugin).use(Locales).use(MotionPlugin).mount("#app");
}).catch(error => {
    console.error("Ошибка перенаправления:", error);
});