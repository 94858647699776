import ApiService from "@/plugins/ApiService";

export default {
    namespaced: true,
    state: {
        user: null,
        token: null,
        stats: {
            views: 0,
            balance: 0,
            wallet: null,
            subscribers: 0,
            views_per_period: 0,
            last_claim_date: null,
            last_bonus_date: null,
            likes: 0,
            admin_channel: null,
            minimum_to_payout: null,
        },
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem("token", token);
        },
        SET_STATS(state, stats) {
            state.stats = { ...state.stats, ...stats };
        },
        LOGOUT(state) {
            state.user = null;
            state.token = null;
            state.stats = {};
            localStorage.removeItem("token");
        },
    },
    actions: {
        async login({ commit }, { user, raw }) {
            try {
                const response = await ApiService.post("/auth/login", { data: raw });
                const { token, ...stats } = response.data;
        
                commit("SET_USER", user);
                commit("SET_TOKEN", stats.data.token);
                commit("SET_STATS", stats.data);

            } catch (error) {
                console.error("Ошибка авторизации:", error);
            }
        },
        async fetchUserStat({ commit }) {
            try {
                const response = await ApiService.get("/user/stat");
                const { ...stats } = response.data;
                commit("SET_STATS", stats.data);
            } catch (error) {
                console.error("Ошибка получения статистики:", error);
            }
        },
    },
};

