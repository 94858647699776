<template>

    <div class="claim__wrapper">

        <div class="score">

            <p class="value">{{ formattedViews }}</p>

            <div class="score__icon">
                <img src="~@/assets/images/icons/mt-coin-l.svg" alt="">
            </div>

        </div>

        <button class="mt__claim__btn" @click="claim()">
            <img src="~@/assets/images/claim.png" alt="">
        </button>

    </div>

</template>

<script>
import ApiService from '@/plugins/ApiService';
import '@/styles/pages/MainPage.scss';
import { mapState } from 'vuex';

export default {
    name: 'Main',
    computed: {
        ...mapState("auth", ["stats", "user"]),
        formattedViews() {
            return this.stats.views.toLocaleString('ru-RU');
        }
    },
    methods: {
        async claim(){
            try{
                const response = await ApiService.get("/user/claim")
                this.$store.dispatch("auth/fetchUserStat");

                if(response.data.status === "success"){
                    this.$modal.show({
                        type: "center",
                        title: this.$t('main.claim.modal.title'),
                        message: this.$t('main.claim.modal.message', { amount: response.data.data.claimed }),
                        icon: 'success',
                        hasAction: true,
                        actionText: this.$t('main.claim.modal.continue')
                    });
                } else {
                    this.$modal.show({
                        type: "center",
                        title: this.$t('main.claim.modal.error'),
                        message: await this.$translateText(response.data.data.message, this.$i18n.locale),
                        icon: 'error',
                        hasAction: true,
                        actionText: this.$t('main.claim.modal.close')
                    });
                }
            } catch (error){
                this.$modal.show({
                    type: "center",
                    title: this.$t('main.claim.modal.error'),
                    message: error,
                    icon: 'error',
                    hasAction: true,
                    actionText: this.$t('main.claim.modal.close')
                });
            }
        }
    }
};
</script>