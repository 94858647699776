import ApiService from "@/plugins/ApiService";

export default {
    namespaced: true,
    state: {
        referralData: null,
        referralLevels: {}
    },
    mutations: {
        SET_REFERRAL_DATA(state, data) {
            state.referralData = data;
        },
        SET_REFERRAL_LEVELS(state, { level, data }) {
            state.referralLevels[level] = data;
        },
    },
    actions: {
        async fetchReferralData({ commit }) {
            try {
                const response = await ApiService.get("/referal/index");
                commit("SET_REFERRAL_DATA", response.data.data);
            } catch (error) {
                console.error("Error loading referral data:", error);
                throw error;
            }
        },
        async fetchReferralListByLevel({ commit }, { level, offset = 0, limit = 1000 }) {
            try {
                const response = await ApiService.get("/referal/list", {
                    level, offset, limit
                });
                commit("SET_REFERRAL_LEVELS", { level, data: response.data.data });
            } catch (error) {
                console.error(`Error loading referral data for level ${level}:`, error);
                throw error;
            }
        },
    },
    getters: {
        referralLevels(state) {
            return state.referralData?.levels || {};
        },
        userId(state) {
            return state.referralData?.userId || null;
        },
        referralLevels(state) {
            return state.referralLevels;
        },
        getReferralByLevel: (state) => (level) => {
            return state.referralLevels[level] || [];
        },
    },
};
