<template>

    <div class="page__info center">

        <div class="page__title">
            
            <button class="back" @click="$router.back()">
                <img src="~@/assets/images/icons/arrow-left.svg" alt="">
            </button>

            <span>{{ $t('ranking.title') }}</span>

        </div>

        <p class="page__span">{{ $t('ranking.description') }}</p>

    </div>

    <div class="ranking__userinfo --inner">

        <p class="nickname">{{ user?.first_name || $t('ranking.user.nickname') }}</p>

        <div class="user__balance">
            <span>{{ stats.views_per_period }}</span>
            <img src="~@/assets/images/icons/mt-coin-l.svg" alt="">
        </div>

    </div>

    <div class="ranking__wrapper">

        <div class="ranking__header">
            <span>{{ $t('ranking.list.place') }}</span>
            <span>{{ $t('ranking.list.coins') }}</span>
        </div>

        <div class="ranking__list">

            <div class="ranking__item" v-for="(user, index) in topUsers" :key="user.id">

                <div class="left">

                    <div class="number">
                        <img v-if="isNaN(user.rank)" :src="require(`@/assets/images/icons/ranking/${user.rank}.svg`)" alt="" />
                        <span v-else>{{ user.rank }}</span>
                    </div>

                    <p>{{ user.first_name }}</p>
                </div>

                <div class="right">
                    <span>{{ formatIncome(user.income) }}</span>
                    <img src="~@/assets/images/icons/mt-coin-l.svg" alt="">
                </div>

            </div>

        </div>

    </div>
    
</template>

<script>
import ApiService from '@/plugins/ApiService';
import '@/styles/pages/RankingPage.scss';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'Ranking',
    computed: {
        ...mapState("auth", ["stats", "user"]),
        ...mapState("ranking", ["topUsers"]),
    },
    methods: {
        formatIncome(value) {
            return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(value);
        }
    }
}
</script>