import ApiService from "@/plugins/ApiService";

export default {
    namespaced: true,
    state: {
        topUsers: [],
    },
    mutations: {
        SET_TOP_USERS(state, users) {
            state.topUsers = users;
        },
    },
    actions: {
        async fetchTopUsers({ commit }) {
            try {
                const response = await ApiService.get("/user/top");
                const users = response.data.data;

                const sortedUsers = Object.values(users)
                    .sort((a, b) => b.income - a.income)
                    .slice(0, 100)
                    .map((user, index) => {
                        let rank;
                        switch (index) {
                            case 0:
                                rank = "gold";
                                break;
                            case 1:
                                rank = "silver";
                                break;
                            case 2:
                                rank = "bronze";
                                break;
                            default:
                                rank = (index + 1).toString();
                        }
                        return { ...user, rank };
                    });

                commit("SET_TOP_USERS", sortedUsers);
            } catch (error) {
                console.error("Ошибка загрузки топа игроков:", error);
            }
        },
    },
};