<template>

    <header class="header">

        <div class="header__profile" v-if="routePath !== '/friends'">

            <p class="username">{{ user?.first_name || 'Undefined' }}</p>

            <div class="settings">
                
                <div 
                    class="settings__btn" 
                    ref="settingsBtn" 
                    @click="toggleDropdown"
                    v-motion
                    :hover="{ scale: 1.1 }"
                >
                    <img src="~@/assets/images/icons/settings.svg" alt="">
                </div>

                <div v-if="isDropdownOpen" class="dropdown" ref="dropdown">

                    <div v-for="(label, lang) in languages" :key="lang" @click="changeLanguage(lang)" class="dropdown__item">
                        
                        <div class="lang__info">
                            <img :src="flags[lang]" class="flag" alt="">
                            {{ label }}
                        </div>

                        <SvgIcon v-if="$i18n.locale === lang" :src="require('@/assets/images/icons/checked.svg')" customClass="checked__icon" />
                    </div>

                </div>

            </div>


        </div>

        <div class="header__stats" v-if="routePath !== '/friends'">

            <span class="profit">{{ $t('header.total_profit') }}</span>

            <div class="header__stats__list">

                <div class="stats__item --inner mt-coin">

                    <div class="stats__icon">
                        <img src="~@/assets/images/icons/mt-coin.svg" alt="">
                    </div>

                    <p class="stats__score">+{{ stats.views_per_period }}</p>

                </div>

                <div class="stats__item --inner likes">

                    <div class="stats__icon">
                        <img src="~@/assets/images/icons/likes.svg" alt="">
                    </div>

                    <p class="stats__score">{{ stats.likes }}</p>

                </div>

                <div class="stats__item --inner gift" @click="goTo('/bonus')">

                    <div class="stats__icon">
                        <img src="~@/assets/images/icons/gift.svg" alt="">
                    </div>

                </div>

                <div class="stats__item --inner cup" @click="goTo('/ranking')">

                    <div class="stats__icon">
                        <img src="~@/assets/images/icons/cup.svg" alt="">
                    </div>

                </div>

            </div>

        </div>

        <div class="header__profit --inner" v-if="routePath == '/friends'">

            <div class="info">

                <div class="icon">
                    <img src="~@/assets/images/icons/likes.svg" alt="">
                </div>

                <div class="value">
                    <p>{{ referralData?.partner_balance || 0.00 }}</p>
                    <span>{{ $t('header.current_profit') }}</span>
                </div>

            </div>

            <button 
                class="primary__btn"
                :disabled="!(referralData?.partner_balance > 0)"
                @click="collectReferralRewards"
            >
                <img v-if="isLoading" class="loader" src="~@/assets/images/icons/loader.svg" >
                <span v-else>{{ $t('header.claim') }}</span>
            </button>

        </div>

    </header>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import ApiService from '@/plugins/ApiService';
import SvgIcon from './SvgIcon.vue';

export default {
    name: "Header",
    components:{
        SvgIcon
    },
    data() {
        return {
            isLoading: false,
            isDropdownOpen: false,
            languages: {
                en: "English",
                ru: "Русский"
            },
            flags: {
                en: require('@/assets/images/icons/flags/en.svg'),
                ru: require('@/assets/images/icons/flags/ru.svg')
            }
        };
    },
    props: {
        routePath: String
    },
    computed: {
        ...mapState("auth", ["stats", "user"]),
        ...mapState("referal", ["referralData"]),
        formatted(value) {
            return Number(value).toLocaleString('ru-RU');
        }
    },
    methods: {
        ...mapActions("referal", ["fetchReferralData"]),
        goTo(path) {
            this.$router.push(path);
        },
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        changeLanguage(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem("language", lang);
            this.isDropdownOpen = false;
        },
        async collectReferralRewards() {
            if (this.isLoading) return;
            this.isLoading = true;

            try{
                const response = await ApiService.get("/referal/claim")
                this.fetchReferralData();
                
                if(response.data.status === "success"){
                    this.$modal.show({
                        type: "center",
                        title: this.$t('header.modal.get'),
                        message: '+' + response.data.data.claimed + 
                        `
                            <div class="icon">
                                <img src="${require('@/assets/images/icons/likes.svg')}" alt="">
                            </div>
                        `,
                        icon: 'success',
                        hasAction: true,
                        actionText: this.$t('header.modal.continue')
                    });
                } else {
                    this.$modal.show({
                        type: "center",
                        title: this.$t('header.modal.error'),
                        message: await this.$translateText(response.data.data.message, this.$i18n.locale),
                        icon: 'error',
                        hasAction: true,
                        actionText: this.$t('header.modal.close')
                    });
                }

            } catch (error){
                this.$modal.show({
                    type: "center",
                    title: this.$t('header.modal.error'),
                    message: error,
                    icon: 'error',
                    hasAction: true,
                    actionText: this.$t('header.modal.close')
                });
            }finally {
                this.isLoading = false;
            }
        },
        closeDropdown(event) {
            if (!this.isDropdownOpen) return;
            const dropdown = this.$refs.dropdown;
            const settingsBtn = this.$refs.settingsBtn;
            
            if (dropdown && settingsBtn && !dropdown.contains(event.target) && !settingsBtn.contains(event.target)) {
                this.isDropdownOpen = false;
            }
        }
    },
    mounted() {
        document.addEventListener("mousedown", this.closeDropdown);
    },
    beforeUnmount() {
        document.removeEventListener("mousedown", this.closeDropdown);
    }
}
</script>